import './index.css'

import AboutList from '../AboutList'

const aboutDetails = [{
  id:1,
  title:'AIM',
  details:'To manufacture and market the day-to-day ethical products to support the need of medical profession – We manufacture as per G.M.P (Good manufacturing practice) certified and complies with latest schedule.'
},{
  id:2,
  title:'QUALITY',
  details:'All the medical products are tested from the raw materials to finished products.  The test are carried out by the most senior testing laboratories , and every product rolls out from skv speaks by itself for quality which results in ultimate patient comfort,cure and satisfaction ,as reported by doctors from different segments. Every raw material and finished product has supportive  “analytical report”.'
},{
  id:3,
  title:'PRODUCTS',
  details:'The segment of products covers vitamins, antacids, anti microbials, anti allergics, and dermatological range and ayurvedic preparations. These products are promoted ethically by meeting practicing doctors. The products are distributed by leading distributors. '
},{
  id:4,
  title:'MEN',
  details:'SKV marketing team comprises of seniors to juniors technically qualified. Giving  good service to hospitals,doctors consultants  periodically  making availability  of  medicines by fastest courier  are the top priority  of  our  team with  fullest dedication and sincerity.'
},{
  id:5,
  title:'COMMITMENT',
  details:'We understand the need of the doctors and medical profession and we operate and work with other manufacturers as franchisee to bring out latest molecules which have a major role in doctors office.'
},{
  id:6,
  title:'CONCLUSION',
  details:'We understand and support life in SKV we say “Every life is an inspiration” and support the medical profession to increase the longevity of life, in a healthy way. '
}]

const About = () => (
  <div className='about-container' id="about">
    <h1 className='about-main-head'>About</h1>
    <ul className="about-list-container">
        {aboutDetails.map(eachItem => (
          <AboutList key={eachItem.id} aboutDetails={eachItem} />
        ))}
      </ul>
  </div>
)

export default About
