import React, { useState } from 'react';

//import {Link} from 'react-router-dom'

import company_logo from '../../assets/company_logo.jpg'

import './index.css'

{/* 
import {GiHamburgerMenu} from 'react-icons/gi'
import {IoMdClose} from 'react-icons/io'
import {AiFillHome} from 'react-icons/ai'
import {BsInfoCircleFill} from 'react-icons/bs' 
*/}


function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App-nav">
      <nav className="navbar">
        <div>
            <a href="#" className="link">
                <img
                src={company_logo}
                alt="website logo"
                className="logo"
                />
            </a>
        </div>
        <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
          <li><a href="#" className="link">Home</a></li>
          <li><a href="#about" className="link">About</a></li>
          {/* Add more navigation items as needed */}
        </ul>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
