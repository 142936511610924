import './index.css'

import company_logo from '../../assets/company_logo.jpg'

const Footer = () => (
    <div className="footer-section">
        <div className="contact-info">
            <img src={company_logo} alt='footerLogo' className='footer-logo' />
            <p className="footer-section-mail-id">Email: <a href="mailto:skvpharmakkd@gmail.com">skvpharmakkd@gmail.com</a></p>
            <p className="footer-section-mail-id ">Phone: <a href="tel:+916383447911">6383447911</a></p>
            <p className="footer-section-mail-id ">1/14-d,thillai nagar 1st street managiri, Karaikudi-630307, Tamil Nadu. India.</p>
      </div>
    </div>
)

export default Footer