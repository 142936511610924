import './index.css'
import Header from '../Header'
import About from '../About'
import Footer from '../Footer'
import {HomeBgContainer} from './styledComponents'

const Home = () => (
  <div>
    <Header />
    <HomeBgContainer>
      <div className='home-inner-card'>
        <h1 className='home-head'>WELCOME TO SKV PHARMA</h1>
        <p className='home-desc'>Our company was established in 2012.We are engaged in offering an effective range of pharmaceutical drugs including antibacterial drugs.
          Our wide range of pharmaceutical bulk drugs includes analgesics, antibiotics and antifungal, Antihistaminics ,anti psychotics, Nutraceuticals and oral hypoglycaemic agents. 
        We also offer skin preparetions and vitamins. Our commitment is to work with patients to deliver innovative medicines in order to improve the health and well being of patients.</p>
      </div>
    </HomeBgContainer>
    <About />
    <Footer />
  </div>
)

export default Home