import Home from './components/Home'
import './App.css'

{/*import {Routes, Route} from 'react-router-dom'
import Header from './components/Header'
import About from './components/About'
import NotFound from './components/NotFound'*/}

const App = () => (
  <>
    <Home/>
  </>
)

export default App
