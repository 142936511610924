import './index.css'

const AboutList = props => {
  const {aboutDetails} = props
  const {title,details} = aboutDetails

  return (
    <li className="abt-li-item">
        <h2 className="abt-li-name">{title}</h2>
        <p className='abt-li-detail'>{details}</p>
    </li>
  )
}

export default AboutList