import styled from 'styled-components'

import skv_bgimg from '../../assets/skv_bgimg.jpg'

export const HomeBgContainer = styled.div`
    min-height: 100vh;
    background-image: url(${skv_bgimg});
    background-size: cover;
    background-position: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
